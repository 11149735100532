




import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
@Component({
  components: {
    Positive: () => import('./Positive.vue'),
    Negative: () => import('./Negative.vue'),
    Inconclusive: () => import('./Inconclusive.vue')
  }
})
export default class Outcome extends Vue {
private componentName = ''

private created (): void {
  this.isResultExpire()

  // this.$store.dispatch('auth/deleteResultData')
  if (this.$route.params.type === '1') this.componentName = 'Negative'
  else if (this.$route.params.type === '2') this.componentName = 'Positive'
  else this.componentName = 'Inconclusive'
}

private mounted (): void {
  const el: any = document.querySelector('.header-wrapper')
  if (el) {
    el.style.backgroundColor = 'transparent'
  }
}

private async isResultExpire () {
  var userData = await this.$store.dispatch('auth/getUserData')

  var today = new Date()
  // console.log('timediff-> today: ', today)
  // console.log('timediff-> valid_untill', userData.test.valid_until)
  var d1 = new Date(userData.test.valid_until)
  var d2 = new Date(today)

  var diff = d1.getTime() - d2.getTime()
  var timediff = diff / (24 * 60 * 60 * 1000)
  console.log('timediff: ', timediff)

  if (timediff <= 0) {
    this.$router.push({ name: 'demographic welcome' })
  }
}

@Watch('$route')
onRouteChange (route: Route):void | boolean {
  if (route.params.type === '1') this.componentName = 'Negative'
  else if (route.params.type === '2') this.componentName = 'Positive'
  else this.componentName = 'Inconclusive'
}

private destroyed (): void {
  this.$store.dispatch('auth/setDemographic', false)
  const el: any = document.querySelector('.header-wrapper')
  if (el) {
    el.style.backgroundColor = '#eaf4fc'
  }
}
}
